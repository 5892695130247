import request from '@/utils/request'; // 获取回访列表

export function rvList(data) {
  return request({
    url: '/manager/rv/list',
    method: 'post',
    data: data
  });
} // 获取回访类型列表

export function rvTypes(data) {
  return request({
    url: '/manager/rv/types',
    method: 'get',
    params: data
  });
} // 新增回访

export function addRv(data) {
  return request({
    url: '/manager/rv/add',
    method: 'post',
    data: data
  });
} // 删除回访

export function delRv(data) {
  return request({
    url: '/manager/rv/del',
    method: 'get',
    params: data
  });
} // 删除回访-批量

export function batchDel(data) {
  return request({
    url: '/manager/rv/batchDelv2',
    method: 'post',
    data: data
  });
} // 修改回访

export function rvUpdate(data) {
  return request({
    url: '/manager/rv/update',
    method: 'post',
    data: data
  });
} // 批量新增回访

export function rvBatchAdd(data) {
  return request({
    url: '/manager/rv/batchAdd',
    method: 'post',
    data: data
  });
} // 获取回访计划项目列表

export function ctmrvname(data) {
  return request({
    url: '/manager/rv/ctmrvname',
    method: 'get',
    params: data
  });
} // 获取回访计划项目详细时间

export function ctmrvdetail(data) {
  return request({
    url: '/manager/rv/ctmrvdetail',
    method: 'get',
    params: data
  });
} // 批量修改回访人员

export function rvGp(data) {
  return request({
    url: '/manager/rv/gp',
    method: 'post',
    data: data
  });
} // 回访导出

export function rvExport(data) {
  return request({
    url: '/manager/rv/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}