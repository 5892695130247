import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
/* Router Modules */

import courtRoute from "./modules/court";
import globalRoute from "./modules/global";
import wz from "./modules/wz"; // import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['root', 'admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    keepAlive: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * 没有权限要求的路由
 * 所有角色都可以访问
 */

export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: '主页',
      icon: 'dashboard'
    } //, affix: true

  }]
}, {
  path: '/help',
  component: Layout,
  redirect: '/help/index',
  name: 'helpGuard',
  meta: {
    title: '帮助文档',
    icon: 'link'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/help/index');
    },
    name: 'ALLHelp',
    meta: {
      title: '帮助文档',
      roles: ['root', 'admin', 'editor', 'operator']
    }
  }]
}, {
  path: '/message',
  component: Layout,
  redirect: '/message/index',
  name: 'messageGuard',
  meta: {
    title: '站内消息',
    icon: 'message' // keepAlive: true,
    // roles: ['root', 'admin', 'posadmin', 'hradmin', 'operator', 'dean'], // you can set roles in root nav

  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/messages/index');
    },
    name: 'ALLMessage',
    meta: {
      title: '站内消息',
      roles: ['root', 'admin', 'editor', 'operator']
    }
  }]
}, globalRoute, courtRoute, // wz,
{
  path: '/symptom',
  component: Layout,
  redirect: '/symptom/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/operate/symptom');
    },
    name: 'SymptomTarget',
    meta: {
      title: '适应症目标报表',
      keepAlive: true,
      hidden: true,
      roles: ['root', 'admin', 'editor', 'operator']
    }
  }]
}, {
  path: '/task',
  component: Layout,
  redirect: '/task/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/components/uploadTask');
    },
    name: 'Task',
    meta: {
      title: '任务列表',
      keepAlive: true,
      hidden: true,
      roles: ['root', 'admin', 'editor', 'operator']
    }
  }]
}];
/**
 * asyncRoutes
 * 需要根据用户角色动态加载的路由
 */

export var asyncRoutes = [{
  path: '/callFuc',
  component: Layout,
  redirect: '/callFuc/index',
  alwaysShow: true,
  // will always show the root menu
  name: 'CallFuc',
  meta: {
    title: '报单预约',
    icon: 'call' // keepAlive: true,
    // roles: ['root', 'admin', 'posadmin', 'hradmin', 'operator', 'dean'], // you can set roles in root nav

  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/callFuc/call');
    },
    name: 'Call',
    meta: {
      title: '报单' // roles: ['root', 'admin', 'posadmin', 'hradmin', 'operator', 'dean'],

    }
  }, {
    path: 'fuc',
    component: function component() {
      return import('@/views/callFuc/fuc');
    },
    name: 'Fuc',
    meta: {
      title: '预约' // roles: ['root', 'admin', 'operator'],

    }
  } // {
  //   path: 'shift',
  //   component: () => import('@/views/callFuc/shift'),
  //   name: 'Shift',
  //   hidden: true,
  //   meta: { title: '职员排班管理', icon: 'clipboard', keepAlive: true, roles: ['admin'] },
  // },
  // {
  //   path: 'fucSetting',
  //   component: () => import('@/views/callFuc/fuc-setting'),
  //   name: 'FucSetting',
  //   hidden: true,
  //   meta: { title: '预约管理', icon: 'clipboard', keepAlive: true, roles: ['admin'] },
  // },
  ]
}, {
  path: '/returnVisit',
  redirect: '/returnVisit/index',
  component: Layout,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/customer/rvManage');
    },
    name: 'ReturnVisit',
    meta: {
      title: '回访管理',
      icon: 'star',
      noCache: true,
      roles: ['root', 'admin', 'operator']
    }
  }]
}, {
  path: '/reception',
  component: Layout,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/reception/index');
    },
    name: 'Documentation',
    meta: {
      title: '分诊接待',
      icon: 'guide',
      keepAlive: true,
      roles: ['root', 'admin', 'operator']
    }
  }]
}, {
  path: '/consult',
  component: Layout,
  redirect: '/consult/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/consult/index');
    },
    name: 'ConsultIndex',
    meta: {
      title: '现场咨询',
      icon: 'ask',
      keepAlive: true,
      roles: ['root', 'admin', 'operator']
    }
  }]
}, {
  path: '/consult2',
  component: Layout,
  redirect: '/consult/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/consult/index');
    },
    name: 'ConsultIndex2',
    meta: {
      title: '现场咨询*',
      icon: 'ask',
      keepAlive: true,
      roles: ['root', 'admin', 'operator']
    }
  }]
}, {
  path: '/cash',
  component: Layout,
  redirect: '/cash/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/cash/index');
    },
    name: 'Cash',
    meta: {
      title: '前台收银',
      icon: 'cash',
      keepAlive: true,
      roles: ['root', 'admin', 'editor', 'operator']
    }
  }]
}, {
  path: '/comsume',
  component: Layout,
  redirect: '/comsume/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/comsume/index');
    },
    name: 'Department',
    meta: {
      title: '科室消耗',
      icon: 'consume',
      keepAlive: true,
      roles: ['root', 'admin', 'editor', 'operator']
    }
  }]
}, {
  path: '/stock',
  component: Layout,
  redirect: '/stock/index',
  alwaysShow: true,
  // will always show the root menu
  name: 'Stock',
  meta: {
    title: '库存管理',
    icon: 'component',
    roles: ['root', 'admin', 'editor', 'operator'] // you can set roles in root nav

  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/stock/index');
    },
    name: 'BaseIndex',
    meta: {
      title: '仓库基础管理',
      roles: ['root', 'admin', 'operator'] // or you can only set roles in sub nav

    }
  }, {
    path: 'put',
    component: function component() {
      return import('@/views/stock/put');
    },
    name: 'Put',
    meta: {
      title: '产品入库管理',
      roles: ['root', 'admin', 'operator'] // if do not set roles, means: this page does not require permission

    }
  }, {
    path: 'putAudit',
    component: function component() {
      return import('@/views/stock/put-audit');
    },
    name: 'PutAudit',
    meta: {
      title: '产品入库审核',
      roles: ['root', 'admin', 'operator'] // if do not set roles, means: this page does not require permission

    }
  }, {
    path: 'out',
    component: function component() {
      return import('@/views/stock/out');
    },
    name: 'Out',
    meta: {
      title: '产品出库管理',
      roles: ['root', 'admin', 'operator'] // if do not set roles, means: this page does not require permission

    }
  }, {
    path: 'profile',
    component: function component() {
      return import('@/views/stock/profile');
    },
    name: 'Profile',
    meta: {
      title: '产品库存管理',
      roles: ['root', 'admin', 'operator'] // if do not set roles, means: this page does not require permission

    }
  }, {
    path: 'report',
    component: function component() {
      return import('@/views/stock/report');
    },
    name: 'Report',
    meta: {
      title: '产品库存报表',
      roles: ['root', 'admin', 'operator'] // if do not set roles, means: this page does not require permission

    }
  }]
}, {
  path: '/check',
  component: Layout,
  redirect: '/check/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/check/index');
    },
    name: 'Check',
    meta: {
      title: '审核管理',
      icon: 'people',
      keepAlive: true,
      roles: ['root', 'admin', 'posadmin', 'dean']
    }
  }]
}, {
  path: '/customer',
  component: Layout,
  redirect: '/customer/index',
  alwaysShow: true,
  // will always show the root menu
  name: 'Customer',
  meta: {
    title: '客户管理',
    icon: 'peoples',
    roles: ['root', 'admin', 'editor'] // you can set roles in root nav

  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/customer/index');
    },
    name: 'Index',
    meta: {
      keepalive: true,
      title: '客户列表',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'balance',
    component: function component() {
      return import('@/views/customer/balance');
    },
    name: 'Balance',
    meta: {
      keepalive: true,
      title: '客户余额差异表',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'unit',
    component: function component() {
      return import('@/views/customer/unit');
    },
    name: 'Unit',
    meta: {
      keepalive: true,
      title: '集团顾客管理',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'score',
    component: function component() {
      return import('@/views/customer/score');
    },
    name: 'score',
    meta: {
      keepalive: true,
      title: '积分记录',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'present',
    component: function component() {
      return import('@/views/customer/present');
    },
    name: 'present',
    meta: {
      keepalive: true,
      title: '赠送记录',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'voucher',
    component: function component() {
      return import('@/views/customer/voucher');
    },
    name: 'voucher',
    meta: {
      keepalive: true,
      title: '券额记录',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'map',
    component: function component() {
      return import('@/views/customer/map');
    },
    name: 'Map',
    meta: {
      title: '搜索附近',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'evaluate',
    component: function component() {
      return import('@/views/customer/evaluate');
    },
    name: 'Evaluate',
    meta: {
      title: '评价管理',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'meaning',
    component: function component() {
      return import('@/views/customer/meaning');
    },
    name: 'Meaning',
    meta: {
      title: '异议管理',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'mbpg',
    component: function component() {
      return import('@/views/customer/mbpg');
    },
    name: 'Mbpg',
    meta: {
      title: '面部评估',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, // {
  //   path: 'returnVisit',
  //   component: () => import('@/views/returnVisit/rvManage'),
  //   name: 'ReturnVisit',
  //   meta: {
  //     title: '回访管理',
  //     roles: ['admin'] // or you can only set roles in sub nav
  //   }
  // },
  {
    path: 'valueAnalysis',
    component: function component() {
      return import('@/views/customer/valueAnalysis');
    },
    name: 'valueAnalysis',
    meta: {
      title: '价值分析',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, // {
  //   path: 'manager',
  //   component: () => import('@/views/customer/manager'),
  //   name: 'Manager',
  //   meta: {
  //     title: '小黑裙俱乐部',
  //     roles: ['admin'], // or you can only set roles in sub nav
  //   },
  // },
  {
    path: 'partner',
    component: function component() {
      return import('@/views/customer/partner');
    },
    name: 'Partner',
    meta: {
      title: '合伙人俱乐部',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }]
}, {
  path: '/project',
  component: Layout,
  redirect: '/project/index',
  alwaysShow: true,
  // will always show the root menu
  name: 'Project',
  meta: {
    title: '项目管理',
    icon: 'tree-table',
    roles: ['root', 'admin', 'editor'] // you can set roles in root nav

  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/project/index');
    },
    name: 'project',
    meta: {
      title: '项目列表',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'packages',
    component: function component() {
      return import('@/views/project/packages');
    },
    name: 'Packages',
    meta: {
      title: '套餐列表',
      roles: ['admin'] // if do not set roles, means: this page does not require permission

    }
  }]
}, // {
//   path: '/materials',
//   component: Layout,
//   redirect: '/materials/page',
//   alwaysShow: true, // will always show the root menu
//   name: 'Materials',
//   meta: {
//     title: '物料管理',
//     icon: 'example',
//     roles: ['root', 'admin', 'editor'] // you can set roles in root nav
//   },
//   children: [
//     {
//       path: 'change',
//       component: () => import('@/views/materials/change'),
//       name: 'change',
//       meta: {
//         title: '产品变动汇总',
//         roles: ['admin'] // or you can only set roles in sub nav
//       }
//     },
//     {
//       path: 'purchase',
//       component: () => import('@/views/materials/purchase'),
//       name: 'purchase',
//       meta: {
//         title: '进货管理'
//         // if do not set roles, means: this page does not require permission
//       }
//     },
//     {
//       path: 'inventory',
//       component: () => import('@/views/materials/inventory'),
//       name: 'Inventory',
//       meta: {
//         title: '盘点管理'
//         // if do not set roles, means: this page does not require permission
//       }
//     },
//     {
//       path: 'warehousing',
//       component: () => import('@/views/materials/warehousing'),
//       name: 'warehousing',
//       meta: {
//         title: '入库管理'
//         // if do not set roles, means: this page does not require permission
//       }
//     }
//   ]
// },
{
  path: '/employee',
  component: Layout,
  redirect: '/employee/performance',
  alwaysShow: true,
  // will always show the root menu
  name: 'Staff',
  meta: {
    title: '员工管理',
    icon: 'staff',
    roles: ['root', 'admin', 'hradmin'] // you can set roles in root nav

  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/employee/index');
    },
    name: 'EmpProfile',
    meta: {
      title: '基本信息',
      roles: ['root', 'admin', 'hradmin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'kpi',
    component: function component() {
      return import('@/views/employee/kpi');
    },
    name: 'kpi',
    meta: {
      title: '工资绩效',
      roles: ['root', 'admin', 'hradmin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'performance',
    component: function component() {
      return import('@/views/employee/performance');
    },
    name: 'staff',
    meta: {
      title: '业绩管理',
      roles: ['root', 'admin', 'hradmin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'deduction',
    component: function component() {
      return import('@/views/employee/deduction');
    },
    name: 'deduction',
    meta: {
      title: '提成管理',
      roles: ['root', 'admin', 'hradmin'] // or you can only set roles in sub nav

    }
  }]
}, {
  path: '/midecal',
  component: Layout,
  redirect: '/midecal/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/midecal/index');
    },
    name: 'midecal',
    meta: {
      title: '电子病历',
      keepAlive: true,
      icon: 'link',
      roles: ['root', 'admin', 'posadmin', 'dean']
    }
  }]
}, {
  path: '/revenue',
  component: Layout,
  redirect: '/revenue/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/revenue/index');
    },
    name: 'Revenue',
    meta: {
      title: '营收管理',
      keepAlive: true,
      icon: 'consume',
      roles: ['root', 'admin', 'posadmin', 'dean']
    }
  }]
}, {
  path: '/revenue',
  component: Layout,
  redirect: '/revenue/mobile',
  children: [{
    path: 'mobile',
    component: function component() {
      return import('@/views/revenue/index');
    },
    name: 'revenueMobile',
    meta: {
      title: '营收管理(移动端)',
      keepAlive: true,
      icon: 'consume',
      roles: ['root', 'admin', 'posadmin', 'dean']
    }
  }]
}, // {
//   path: '/account',
//   component: Layout,
//   redirect: '/account/index',
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/account/index'),
//       name: 'account',
//       meta: {
//         title: '营收外账',
//         keepAlive: true,
//         icon: 'tab',
//         roles: ['root', 'admin', 'posadmin', 'dean'],
//       },
//     },
//   ],
// },
// {
//   path: '/consume',
//   component: Layout,
//   redirect: '/consume/index',
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/consume/index'),
//       name: 'Consume',
//       meta: { title: '消费管理', icon: 'documentation', keepAlive: true,
//       roles: ['root', 'admin', 'posadmin', 'dean'] }
//     }
//   ]
// },
// {
//   path: '/expend',
//   component: Layout,
//   redirect: '/expend/index',
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/expend/index'),
//       name: 'Expend',
//       meta: { title: '消耗管理', icon: 'excel', keepAlive: true,
//       roles: ['root', 'admin', 'posadmin', 'dean'] }
//     }
//   ]
// },
// {
//   path: '/search',
//   component: Layout,
//   redirect: '/search/index',
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/search/index'),
//       name: 'Search',
//       meta: { title: '综合查询', icon: 'search', keepAlive: true }
//     }
//   ]
// },
{
  path: '/system',
  component: Layout,
  redirect: '/system/index',
  alwaysShow: true,
  // will always show the root menu
  name: 'System',
  meta: {
    title: '系统报表',
    icon: 'nested',
    keepAlive: true,
    roles: ['root', 'admin', 'editor'] // you can set roles in root nav

  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/system/index');
    },
    name: 'SystemComsume',
    meta: {
      title: '客户交易',
      roles: ['root', 'admin', 'posadmin', 'dean']
    }
  } // {
  //   path: 'serve',
  //   component: () => import('@/views/customer/serve'),
  //   name: 'serve',
  //   meta: {
  //     title: '客户日常管理',
  //     roles: ['admin'], // or you can only set roles in sub nav
  //   },
  // },
  // {
  //   path: 'source',
  //   component: () => import('@/views/customer/source'),
  //   name: 'Source',
  //   meta: {
  //     title: '来源统计',
  //     roles: ['admin'], // or you can only set roles in sub nav
  //   },
  // },
  // {
  //   path: 'balance',
  //   component: () => import('@/views/system/balance'),
  //   name: 'Balance',
  //   meta: {
  //     title: '项目和定金',
  //     roles: ['admin'], // or you can only set roles in sub nav
  //   },
  // },
  ]
}, {
  path: '/operate',
  component: Layout,
  redirect: '/operate/index',
  alwaysShow: true,
  // will always show the root menu
  name: 'Operate',
  meta: {
    title: '运营报表',
    icon: 'nested',
    keepAlive: true,
    roles: ['root', 'admin', 'editor'] // you can set roles in root nav

  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/operate/court');
    },
    name: 'operateIndex',
    meta: {
      title: '分院报表',
      roles: ['admin']
    }
  }, {
    path: 'monthlyManage',
    component: function component() {
      return import('@/views/operate/target');
    },
    name: 'MonthlyManage',
    meta: {
      title: '目标报表',
      roles: ['admin']
    }
  }, {
    path: 'estimateManage',
    component: function component() {
      return import('@/views/estimate/index');
    },
    name: 'EstimateManage',
    meta: {
      title: '咨询评估',
      roles: ['admin']
    }
  }, {
    path: 'monthManage',
    component: function component() {
      return import('@/views/operate/month');
    },
    name: 'MonthManage',
    meta: {
      title: '经营月报',
      roles: ['admin']
    }
  }]
}, {
  path: '/manage',
  component: Layout,
  redirect: '/manage/index',
  name: 'Eare',
  meta: {
    title: '后台管理',
    icon: 'tree',
    roles: ['root', 'admin', 'editor'] // you can set roles in root nav

  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/manage/index');
    },
    name: 'index',
    meta: {
      title: '基础信息管理',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: '/kingdee',
    component: function component() {
      return import('@/views/manage/kingdee');
    },
    name: 'Kingdee',
    meta: {
      title: '金蝶基础资料',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, // {
  //   path: 'information',
  //   component: () => import('@/views/manage/information'),
  //   name: 'Employee',
  //   meta: {
  //     title: '后台信息管理',
  //     roles: ['admin']
  //     // if do not set roles, means: this page does not require permission
  //   }
  // },
  {
    path: 'userInfo',
    component: function component() {
      return import('@/views/manage/userInfo');
    },
    name: 'Employee',
    meta: {
      title: '用户信息管理',
      roles: ['admin'] // if do not set roles, means: this page does not require permission

    }
  }]
}, {
  path: '/miniapp',
  component: Layout,
  redirect: '/miniapp/index',
  alwaysShow: true,
  // will always show the root menu
  name: 'miniapp',
  meta: {
    title: '小程序后台配置',
    icon: 'wechat',
    roles: ['root', 'admin', 'editor'] // you can set roles in root nav

  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/miniapp/index');
    },
    name: 'miniappHome',
    meta: {
      title: '首页',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'score',
    component: function component() {
      return import('@/views/miniapp/score');
    },
    name: 'miniappScore',
    meta: {
      title: '产品配置',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'productVerification',
    component: function component() {
      return import('@/views/miniapp/productVerification');
    },
    name: 'miniappProductVerification',
    meta: {
      title: '产品核销',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'welfare',
    component: function component() {
      return import('@/views/miniapp/welfare');
    },
    name: 'miniappWelfare',
    meta: {
      title: '福利专区',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'branch',
    component: function component() {
      return import('@/views/miniapp/branch');
    },
    name: 'miniappBranch',
    meta: {
      title: '移动端审核',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'mine',
    component: function component() {
      return import('@/views/miniapp/mine');
    },
    name: 'miniappMine',
    meta: {
      title: '顾客信息',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'question',
    component: function component() {
      return import('@/views/miniapp/question/questionnaire');
    },
    name: 'questionnaire',
    meta: {
      title: '调查问卷',
      keepAlive: true,
      roles: ['root', 'admin', 'posadmin', 'dean']
    }
  }]
}, {
  path: '/mianWeb',
  component: Layout,
  redirect: '/mianWeb/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/mianWeb/index');
    },
    name: 'mianWeb',
    meta: {
      title: '官网配置管理',
      keepAlive: true,
      icon: 'consume',
      roles: ['root', 'admin', 'posadmin', 'dean']
    }
  }]
}, {
  path: '/case',
  component: Layout,
  redirect: '/case/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/case/index');
    },
    name: 'Case',
    meta: {
      title: '案例配置',
      icon: 'example'
    } //, affix: true

  }]
}, {
  path: '/handbook',
  component: Layout,
  redirect: '/handbook/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/handbook/index');
    },
    name: 'Handbook',
    meta: {
      title: '价格手册',
      icon: 'pdf'
    } //, affix: true

  }]
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  console.log('constantRoutes', constantRoutes);
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;